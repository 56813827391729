import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import MentalHealthTopics from "@olivahealth/graphql-server/src/domain/value-objects/MentalHealthTopics";
import ServiceType from "@olivahealth/graphql-server/src/domain/value-objects/ServiceType";
import SessionStatus from "@olivahealth/graphql-server/src/domain/value-objects/SessionStatus";
import logger from "@olivahealth/logger/client";

import WellbeingProfileFocusAreas from "@olivahealth/graphql-server/src/domain/value-objects/WellbeingProfileFocusAreas";
import CareType from "@olivahealth/graphql-server/src/domain/value-objects/CareType";
import TopicBadge from "../../../components/common/TopicBadge";
import useCanAccessProfessionalCoaching from "../../../hooks/useCanAccessProfessionalCoaching";
import { OlivaHook } from "../../../hooks/OlivaHook";
import useTranslation from "../../../hooks/useTranslation";
import { useAmplitude } from "../../../services/contexts/AmplitudeContext";
import { useUserData } from "../../../services/contexts/UserDataContext";
import {
  useEmployeeDashboardQuery as IQuery,
  useEmployeeDashboardQuery$data as IQueryData,
} from "./__generated__/useEmployeeDashboardQuery.graphql";

export interface UseEmployeeDashboardData {
  queryData: IQueryData;
  sessionIdWithPendingCheckIn?: string;

  // Indicates if the organisation has access to professional coaching
  canAccessProfessionalCoaching: boolean;

  // Indicates if the user should be redirected to the new wellbeing profile page from the legacy refresh card
  redirectToNewWellbeingProfileFromV1: boolean;

  // The user's latest wellbeing profile result
  wellbeingProfileResults: IQueryData["wellbeingProfileResults"]["nodes"];

  // A banner shown to users who have not yet completed their first wellbeing profile
  showGetStartedBanner: boolean;

  // The latest triaging submission's focus area
  latestFocusArea: WellbeingProfileFocusAreas | null;

  // Card with wellbeing profile shape preview & CTA to build your wellbeing profile
  // Shown if the user has no triaging submissions completed
  showStartTriagingCard: boolean;

  // Card with your wellbeing profile summary including topic breakdown & wellbeing profile shape
  // Shown if the wellbeingProfile field of useEmployeeDashboardQuery returns data (user has a wellbeing profile)
  showWellbeingProfileV1: boolean;

  // Shown if the employee is not awaiting/choosing between match options
  // i.e. they can refresh if they submitted but haven't asked for a match; or if they are matched and in active therapy
  showRefreshProfileButton: boolean;

  // Card prompting to start 1-to-1 sessions & CTA to get matched
  // Shown if: 1. user does not have a triaging submission completed 2. user has no ongoing therapy
  startCareCard: {
    show: boolean;
    cardContent: ReactNode;
  };

  // Card telling users we are still finding them a match
  // Shown if the user has a NEEDS_MATCHING triaging submission and no ongoing therapy
  showFindingMatchCard: boolean;

  // Card telling the user they have been matched with CTA to schedule session
  // Shown if the user has not started therapy & has no scheduled sessions
  scheduleFirstSessionCard: {
    therapyId?: string;
    cardContent: ReactNode;
    careType?: CareType;
  };

  showPersonalEmailBanner: boolean;

  showPendingSurveyEmployeeUnsatisfiedWithProfessionalBanner: boolean;

  // Card for users who are not sure yet, with prompt to book a check-in
  // Shown if the user has no ongoing therapy
  showBookOneToOneCard: boolean;

  // Shown if the session field of useEmployeeDashboardQuery returns data (user has a scheduled session)
  showBookingConfirmationDialog: boolean;

  // Shown if the latest triaging submission has CHOOSING_MATCH status, meaning the user has therapists to choose from
  showNewMatchesBanner: boolean;
}

export interface UseEmployeeDashboardEffects {
  navigateToSelfGuided: () => void;
  setShowBookingConfirmationDialog: (value: boolean) => void;
}

type UseEmployeeDashboard = OlivaHook<
  UseEmployeeDashboardData,
  UseEmployeeDashboardEffects
>;

const useEmployeeDashboardQuery = graphql`
  query useEmployeeDashboardQuery(
    $userId: String
    $shouldFetchSessionDetails: Boolean!
    $sessionId: ID!
  ) {
    viewer {
      ... on User {
        personalEmail
      }
    }
    session(sessionId: $sessionId) @include(if: $shouldFetchSessionDetails) {
      ... on Session {
        status
        ...BookingConfirmationDialogFragment_session
      }
    }
    wellbeingProfileResults {
      nodes {
        ...WellbeingProfileScoreDisplay_wellbeingProfileResults
      }
    }
    wellbeingProfile {
      __typename
      ... on WellbeingProfile {
        topic
      }
      ...WellbeingProfileCard_data
    }
    ...UpcomingEventsFragment_data @arguments(userId: $userId)
    ...SelfGuided_items
    careEpisodes {
      metadata {
        sessionIdWithPendingCheckIn
        currentFocusArea
        canUpdateWellbeingProfile
      }
      episodes {
        nodes {
          needsToBookFirstSession
          id
          status
          therapy {
            surveyEmployeeUnsatisfiedWithProfessionalPendingSubmissionId
            careType
            id
            status
            isBookingEnabled
          }
        }
      }
    }
  }
`;

interface Props {
  sessionId?: string;
}

export default function useEmployeeDashboard({
  sessionId,
}: Props): UseEmployeeDashboard {
  const { data: user } = useUserData();
  const dropInEnabledInOrgPlan =
    user?.organisation?.plan?.services?.includes(ServiceType.DROP_IN) ?? false;
  const { push } = useRouter();
  const { trackEvent } = useAmplitude();
  const { t, Trans } = useTranslation("employeeDashboard");

  const canAccessProfessionalCoaching = useCanAccessProfessionalCoaching();

  useEffect(() => {
    trackEvent("Homepage viewed");
  }, [trackEvent]);

  const navigateToSelfGuided = () => {
    push("self-guided");
  };

  const data = useLazyLoadQuery<IQuery>(useEmployeeDashboardQuery, {
    userId: user?.id,
    shouldFetchSessionDetails: Boolean(sessionId),
    sessionId: sessionId ?? "",
  });

  const [showBookingConfirmationDialog, setShowBookingConfirmationDialog] =
    useState(
      Boolean(data.session) && data.session?.status === SessionStatus.SCHEDULED,
    );

  if (!data) {
    logger.error(
      "EmployeeDashboard/useEmployeeDashboard",
      "Error fetching data",
      {
        userId: user?.id,
      },
    );

    return {
      status: "error",
      data: {} as UseEmployeeDashboardData,
      effects: {} as UseEmployeeDashboardEffects,
    };
  }

  const hasOngoingTherapy =
    data?.careEpisodes.episodes.nodes.some((node) => {
      return ["ACTIVE", "FOLLOW_UP"].includes(node.status);
    }) ?? false;

  const hasMatchableSubmission = data?.careEpisodes.episodes.nodes.some(
    (item) => {
      return ["CHOOSING_MATCH", "NEEDS_MANUAL_MATCHING"].includes(item.status);
    },
  );

  const hasRequestedMatching = data?.careEpisodes.episodes.nodes.some(
    (item) => {
      return ["NEEDS_MANUAL_MATCHING"].includes(item.status);
    },
  );

  const showGetStartedBanner = Boolean(
    canAccessProfessionalCoaching &&
      data.wellbeingProfile.__typename !== "WellbeingProfile",
  );

  const showStartTriagingCard = data.careEpisodes.episodes.nodes.length === 0;

  const isNotOrMatchableOrInTherapy =
    !hasMatchableSubmission && !hasOngoingTherapy;
  const showStartCare =
    isNotOrMatchableOrInTherapy &&
    (data.wellbeingProfileResults?.nodes ?? []).length !== 0;

  const startCareCardContent = (
    <Trans
      i18nKey={t(
        data.wellbeingProfile.topic
          ? "startCareCard.subtitle"
          : "startCareCard.subtitleEmpty",
        {
          topic: data.wellbeingProfile.topic,
        },
      )}
      components={[
        <TopicBadge
          key={data.wellbeingProfile.topic}
          topic={data.wellbeingProfile.topic as MentalHealthTopics}
        />,
      ]}
    />
  );

  const showFindingMatchCard = hasRequestedMatching && !hasOngoingTherapy;
  const showPersonalEmailBanner = !data.viewer?.personalEmail;

  const scheduleFirstSessionCardContent = (
    <Trans
      i18nKey={t(
        data.wellbeingProfile.topic
          ? "scheduleFirstSessionCard.subtitle"
          : "scheduleFirstSessionCard.subtitleEmpty",
        {
          topic: data.wellbeingProfile.topic,
        },
      )}
      components={[
        <span key="supportBodyName" className="font-bold" />,
        data.wellbeingProfile.topic ? (
          <TopicBadge topic={data.wellbeingProfile.topic} />
        ) : (
          <span />
        ),
      ]}
    />
  );

  const showNewMatchesBanner = data.careEpisodes.episodes.nodes.some(
    (episode) => episode.status === "CHOOSING_MATCH",
  );

  const scheduleFirstSessionForTherapyId =
    data.careEpisodes.episodes.nodes.find(
      (episode) =>
        episode.needsToBookFirstSession && episode.therapy?.isBookingEnabled,
    )?.therapy?.id;
  const scheduleFirstSessionForTherapyCareType =
    data.careEpisodes.episodes.nodes.find(
      (episode) =>
        episode.needsToBookFirstSession && episode.therapy?.isBookingEnabled,
    )?.therapy?.careType;

  const showPendingSurveyEmployeeUnsatisfiedWithProfessionalBanner = Boolean(
    data.careEpisodes.episodes.nodes.some(
      (episode) =>
        episode.therapy
          ?.surveyEmployeeUnsatisfiedWithProfessionalPendingSubmissionId,
    ),
  );

  return {
    status: "success",
    data: {
      queryData: data,
      sessionIdWithPendingCheckIn:
        data.careEpisodes.metadata.sessionIdWithPendingCheckIn || undefined,
      canAccessProfessionalCoaching,
      redirectToNewWellbeingProfileFromV1: isNotOrMatchableOrInTherapy,
      showGetStartedBanner,
      showStartTriagingCard,
      latestFocusArea:
        (data.careEpisodes.metadata
          .currentFocusArea as WellbeingProfileFocusAreas) ?? null,
      startCareCard: {
        show: showStartCare,
        cardContent: startCareCardContent,
      },
      showFindingMatchCard,
      showBookOneToOneCard: !hasOngoingTherapy && dropInEnabledInOrgPlan,
      wellbeingProfileResults: data.wellbeingProfileResults?.nodes,
      showWellbeingProfileV1:
        data.wellbeingProfile.__typename === "WellbeingProfile" &&
        !data.wellbeingProfileResults?.nodes.length,
      showRefreshProfileButton:
        data.careEpisodes.metadata.canUpdateWellbeingProfile,
      showPersonalEmailBanner,
      showPendingSurveyEmployeeUnsatisfiedWithProfessionalBanner,
      scheduleFirstSessionCard: {
        therapyId: scheduleFirstSessionForTherapyId,
        careType: scheduleFirstSessionForTherapyCareType ?? undefined,
        cardContent: scheduleFirstSessionCardContent,
      },
      showBookingConfirmationDialog,
      showNewMatchesBanner,
    },
    effects: {
      navigateToSelfGuided,
      setShowBookingConfirmationDialog,
    },
  };
}
